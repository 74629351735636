import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt, faStar as emptyStar } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './styles/ApartmentDetails.css';

const ApartmentDetails = () => {
  const { id } = useParams();
  const [apartment, setApartment] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get(`https://khah-184f534d3b81.herokuapp.com/api/houses/${id}`)
      .then((response) => {
        setApartment(response.data);
      })
      .catch((err) => {
        setError('Failed to load apartment details. Please try again later.');
        console.error(err);
      });

    ReactGA.send({ hitType: 'pageview', page: `/houses/${id}`, title: `Apartment Details` });
  }, [id]);

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(<FontAwesomeIcon icon={faStar} key={i} className="star-icon" />);
      } else if (i === Math.ceil(rating) && rating % 1 !== 0) {
        stars.push(<FontAwesomeIcon icon={faStarHalfAlt} key={i} className="star-icon" />);
      } else {
        stars.push(<FontAwesomeIcon icon={emptyStar} key={i} className="star-icon empty" />);
      }
    }
    return stars;
  };

  if (error) {
    return <div className="error">{error}</div>;
  }

  if (!apartment) {
    return <div className="loading">Loading...</div>;
  }

  // Social media share details
  const shareUrl = `https://khah-184f534d3b81.herokuapp.com/api/houses/${id}`;
  const message = `Check out this amazing apartment at ${apartment.location}! Price: $${apartment.price} per night.`;

  return (
    <div className="apartment-details-container">
      <h2>{apartment.location}</h2>

      {/* Carousel for displaying all images */}
      <Carousel showThumbs={true} className="image-carousel">
        {apartment.images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Apartment image ${index + 1}`} className="apartment-image" />
          </div>
        ))}
      </Carousel>

      <div className="apartment-details-text">
        <p><strong></strong> <span style={{ color: 'red', fontWeight: 'bold' }}>${apartment.price} per night</span></p>

        <p><strong>Location:</strong> {apartment.location}</p>
        <p><strong>Bedrooms:</strong> {apartment.numberOfBedrooms}</p>
        <p><strong>Description:</strong> {apartment.description}</p>
        <p><strong>Facilities:</strong> {apartment.facilities || 'Not available'}</p>
        <p><strong>Rules:</strong> {apartment.rules || 'Ask Host'}</p>
        <p><strong>Landmarks:</strong> {apartment.landmarks || 'Ask Khah Assistant'}</p>
        <p><strong>Number of Units:</strong> {apartment.numberOfSpaces}</p>
        <p><strong>Type of Apartment:</strong> {apartment.typeOfHouse}</p>
        <p><strong>Proximity to Malls:</strong> Within {apartment.proximityToMalls || 'Not specified'} km(s)</p>
        <p><strong>Proximity to Airport:</strong> Within {apartment.proximityToAirport || 'Not specified'} km(s)</p>
        <p><strong>Personal Assistant:</strong> {apartment.assistName}</p>
        <p><strong>Assistant Phone:</strong> {apartment.assistPhoneNumber}</p>
        <p><strong>Rating:</strong> {renderStars(apartment.rating)}</p>

        <div className="apartment-details-actions">
          <a
            href={`mailto:khahtech2010@gmail.com?subject=Reservation Request for the apartment at ${apartment.location}&body=Hello, I would like to make a reservation for the apartment located at ${apartment.location}. Could you please provide me with more details regarding availability and pricing?`}
            className="inquiry-button"
          >
            Make a Reservation
          </a>
        </div>
        
        {/* Social Media Sharing */}
        <div className="social-share">
          <p>Share this property:</p>
          <a
            href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`}
            target="_blank"
            rel="noopener noreferrer"
            className="share-button facebook"
          >
            <FontAwesomeIcon icon={faFacebook} /> Facebook
          </a>
          <a
            href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`}
            target="_blank"
            rel="noopener noreferrer"
            className="share-button twitter"
          >
            <FontAwesomeIcon icon={faTwitter} /> Twitter
          </a>
          <a
            href={`https://wa.me/?text=${encodeURIComponent(message)}`}
            target="_blank"
            rel="noopener noreferrer"
            className="share-button whatsapp"
          >
            <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp
          </a>
          <a
            href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`}
            target="_blank"
            rel="noopener noreferrer"
            className="share-button linkedin"
          >
            <FontAwesomeIcon icon={faLinkedin} /> LinkedIn
          </a>
        </div>
      </div>
    </div>
  );
};

export default ApartmentDetails;
