import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './styles/Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FaApple, FaGooglePlay } from 'react-icons/fa';

const Spinner = () => <div className="spinner"></div>;

function Home() {
  useEffect(() => {
    document.title = 'Khah | Discover Local Stays';
  }, []);

  const [formData, setFormData] = useState({
    location: '',
  });
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const resultsRef = useRef(null);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResults([]);
    setError('');

    try {
      const response = await axios.post('https://khah-184f534d3b81.herokuapp.com/api/aisearch', formData);
      const data = response.data.result.split('\n').filter((line) => line.trim());
      setResults(data);
    } catch (err) {
      setError('Error: Could not fetch results. Try again later.');
    } finally {
      setLoading(false);
      resultsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="home">
      <header className="home-header">
        <h1>Find Your Stay</h1>
        <p>Private local stays near you.</p>
      </header>

      <div className="download-buttons">
        <a href="https://apps.apple.com/us/app/nzubo/id6444659176" target="_blank" rel="noreferrer" className="btn-download">
          <FaApple className="icon" /> iOS
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.khahNz" target="_blank" rel="noreferrer" className="btn-download">
          <FaGooglePlay className="icon" /> Android
        </a>
      </div>

      <form className="search-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="location">
            <FontAwesomeIcon icon={faMapMarkerAlt} /> Location
          </label>
          <input
            type="text"
            id="location"
            name="location"
            placeholder="Enter your destination"
            value={formData.location}
            onChange={handleInputChange}
            required
          />
        </div>
        <button type="submit" className="btn-submit">
          {loading ? <Spinner /> : 'Search'}
        </button>
      </form>

      <button 
        className="btn-view-stays" 
        onClick={() => window.location.href = '/stays'}
      >
        View All Stays
      </button>

      <div className="results" ref={resultsRef}>
        {error && <p className="error">{error}</p>}
        {results.length > 0 && (
          <ul>
            {results.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
      </div>

      <button className="scroll-top" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
        <FontAwesomeIcon icon={faChevronUp} />
      </button>
    </div>
  );
}

export default Home;
